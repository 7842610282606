import React from "react";
import { Typography } from "@material-tailwind/react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import HeroBgImage from "../../assets/images/footer/footer.png";
import Logo from "../../assets/images/logo.png";
import { ReactComponent as Discord } from "../../assets/images/footer/discord.svg";
import { ReactComponent as Facebook } from "../../assets/images/footer/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/images/footer/twitter.svg";
import { ReactComponent as Youtube } from "../../assets/images/footer/youtube.svg";
import { ReactComponent as YoutubeMob } from "../../assets/images/footer/ytMob.svg";
import { ReactComponent as DiscordMob } from "../../assets/images/footer/discordMob.svg";
import { ReactComponent as FbMob } from "../../assets/images/footer/fbMob.svg";
import { ReactComponent as Xmob } from "../../assets/images/footer/xMob.svg";

const currentYear = new Date().getFullYear();

const LINKS = [
  {
    title: "Get in Touch",
    index: 0,
    items: [
      <div className="w-full font-plexmedium flex items-start md:justify-start color-ibodygrey">
        <div>
          {React.createElement(MapPinIcon, {
            strokeWidth: 1,
            className: "h-6 text-ibodygrey mr-2 mt-1",
          })}
        </div>

        <div className="text-xs flex flex-col xl:text-[1rem] justify-start leading-7">
          Immerso AIIP Limited, <br />
          Second Floor, 18-20 North Quay, <br />
          Douglas, Isle of Man <br className="hidden md:flex" /> IM1 4LE.
        </div>
      </div>,
      // <div className="flex items-center gap-4 md:hidden">
      //   <Phone />
      //   <span>+01234567890</span>
      // </div>,
      // <div className="flex items-center gap-4 md:hidden">
      //   <Mail />
      //   <span>info@immerso.ai</span>
      // </div>,
    ],
    link: [
      <div className="mt-1">
        <Typography className="text-white text-start md:mb-0 font-plexmedium  xl:text-[1rem] text-sm">
          Terms & Conditions
        </Typography>
        <Typography
          variant="small"
          className="mb-4 text-start text-white md:mb-0 font-plexlight text-xs"
        >
          Copyright@immerso.ai {currentYear}
        </Typography>
      </div>,
    ],
  },
  //   { title: "", items: [] },
  {
    index: 1,
    title: "Get Started",
    items: [
      { title: "Home", link: "/" },
      { title: "Pricing", link: "/soon" },
      //   { title: '\n', link: "" },
      // { title: "\n\n", link: "" },
      //   { title: "\n\n", link: "" },
      //   { title: "\n\n\n", link: "" },
    ],
    link: [
      <div className="mt-[130px]">
        <Typography
          as="a"
          href="#"
          color="blue-gray"
          className="font-normal transition-colors text-white font-plexmedium  xl:text-[1rem] text-sm"
        >
          GDPR
        </Typography>
      </div>,
    ],
  },
  {
    index: 2,
    title: "Products",
    items: [
      {
        title: (
          <span>
            {/* Make the first letter green using "text-igreen" */}
            <span className="text-igreen">i</span>ndia ChatGPT
          </span>
        ),
        link: "/products/Iindia",
      },
      {
        title: (
          <span>
            <span className="text-igreen">i</span>Image
          </span>
        ),
        link: "/products/iImage",
      },
      {
        title: (
          <span>
            <span className="text-igreen">i</span>Sports
          </span>
        ),
        link: "/products/iSports",
      },
      {
        title: (
          <span>
            <span className="text-igreen">i</span>Language
          </span>
        ),
        link: "/products/iLanguage",
      },
      {
        title: (
          <span>
            <span className="text-igreen">i</span>Video
          </span>
        ),
        link: "/products/iVideo",
      },
      {
        title: (
          <span>
            <span className="text-igreen">i</span>Music
          </span>
        ),
        link: "/products/iMusic",
      },
    ],
    link: [
      <div>
        <Typography
          as="a"
          href="#"
          color="blue-gray"
          className="font-normal transition-colors text-white font-plexmedium  xl:text-[1rem] text-sm"
        >
          Legal
        </Typography>
      </div>,
    ],
  },
  {
    index: 3,
    title: "Company",
    items: [
      { title: "About Us", link: "/about" },
      { title: "Careers", link: "/career" },
      { title: "News", link: "/news" },
      { title: "Blog", link: "/blog" },
      { title: "Research", link: "/research" },
      { title: "Contact Us", link: "/contact" },
    ],
    link: [
      <div>
        <Typography
          as="a"
          href="/privacy-policy"
          color="blue-gray"
          className="font-normal transition-colors text-white font-plexmedium  xl:text-[1rem] text-sm"
        >
          Privacy Policy
        </Typography>
      </div>,
    ],
  },
];

export default function Footer() {
  return (
    <footer
      className="max-w-[1800px] mx-auto animate-slidein [--slidein-delay:500ms] opacity-0 bg-cover bg-no-repeat bg-center footerBg"
      style={{ backgroundImage: `url(${HeroBgImage})` }}
    >
      <div className="pt-5 md:pt-10  pb-14  container mx-auto flex flex-col">
        <div className="w-full px-4 lg:px-2 py-5">
          <div className="hidden lg:block">
            <img src={Logo} width="260px" alt="immerso-logo"/>
          </div>

          <div className="lg:hidden ">
            <img src={Logo} width="107px" alt="immerso-logo"/>
          </div>
        </div>
        <div className="mx-5">
          <div className="w-full">
            <div className=" flex flex-col">
              <div className="flex flex-col md:flex-row  justify-between">
                {LINKS.map(({ title, items, index, link }) => (
                  <>
                    <ul
                      key={title}
                      className={`${
                        index === 1 ? "md:order-none" : ""
                      } flex flex-col `}
                    >
                      <Typography
                        variant="small"
                        color="white"
                        className="mb-3 my-2 md:my-0 text-sm xl:text-lg	text-left font-plexmedium"
                      >
                        {title}
                      </Typography>

                      <div className="grid grid-cols-2 md:flex md:flex-col">
                        {items.map((links) => (
                          <li
                            key={links}
                            className=" md:flex-col md:justify-between text-start"
                          >
                            <Typography
                              as="a"
                              href={links.link}
                              className="py-2 text-left  text-xs xl:text-[1rem] text-ibodygrey font-plexmedium"
                            >
                              {links.title || links}
                            </Typography>
                          </li>
                        ))}
                      </div>

                      {index === 0 && (
                        <div>
                          <div className="order-1 flex  gap-4  xl:gap-12 pt-3 text-blue-gray-900 sm:justify-start">
                            <Typography
                              as="a"
                              href="https://www.youtube.com/channel/UCFktCbIkBKIRqVwfmqM2h0g"
                              className="opacity-80 h-12 w-12 transition-opacity hover:opacity-100"
                            >
                              <div className="hidden md:block">
                                <Youtube className="xl:h-11 h-10" />
                              </div>
                              <div className="md:hidden">
                                <YoutubeMob />
                              </div>
                            </Typography>
                            <Typography
                              as="a"
                              href="https://discord.com/invite/saa94Fz9"
                              className="opacity-80 h-12 w-12 transition-opacity hover:opacity-100"
                            >
                              <div className="hidden md:block">
                                <Discord className="xl:h-11 h-10" />
                              </div>
                              <div className="md:hidden">
                                <DiscordMob />
                              </div>
                            </Typography>
                            <Typography
                              as="a"
                              href="https://twitter.com/i/flow/login?redirect_after_login=%2FImmersoai"
                              className="opacity-80 h-12 w-12 transition-opacity hover:opacity-100"
                            >
                              <div className="hidden md:block">
                                <Twitter className="xl:h-11 h-10" />
                              </div>
                              <div className="md:hidden">
                                <Xmob />
                              </div>
                            </Typography>
                            <Typography
                              as="a"
                              href="https://www.facebook.com/people/Immeroai/61556574983818/"
                              className="opacity-80 h-12 w-12 transition-opacity hover:opacity-100"
                            >
                              <div className="hidden md:block">
                                <Facebook className="xl:h-11 h-10" />
                              </div>
                              <div className="md:hidden">
                                <FbMob />
                              </div>
                            </Typography>
                          </div>
                        </div>
                      )}
                      <div className="mt-10 xl:text-[1rem] text-sm hidden md:flex">
                        {link}
                      </div>
                    </ul>
                  </>
                ))}
              </div>
              {/* <div className="order-1 flex gap-4 md:gap-12 mt-0 text-blue-gray-900 sm:justify-start">
              <Typography
                as="a"
                href="https://www.youtube.com/channel/UCFktCbIkBKIRqVwfmqM2h0g"
                className="opacity-80 h-12 w-12 transition-opacity hover:opacity-100"
              >
                <div className="hidden md:block">
                  <Youtube className="xl:h-12 h-10"/>
                </div>
                <div className="md:hidden">
                  <YoutubeMob />
                </div>
              </Typography>
              <Typography
                as="a"
                href="https://discord.com/invite/saa94Fz9"
                className="opacity-80 h-12 w-12 transition-opacity hover:opacity-100"
              >
                <div className="hidden md:block">
                  <Discord className="xl:h-12 h-10"/>
                </div>
                <div className="md:hidden">
                  <DiscordMob />
                </div>
              </Typography>
              <Typography
                as="a"
                href="https://twitter.com/i/flow/login?redirect_after_login=%2FImmersoai"
                className="opacity-80 h-12 w-12 transition-opacity hover:opacity-100"
              >
                <div className="hidden md:block">
                  <Twitter className="xl:h-12 h-10"/>
                </div>
                <div className="md:hidden">
                  <Xmob />
                </div>
              </Typography>
              <Typography
                as="a"
                href="https://www.facebook.com/people/Immeroai/61556574983818/"
                className="opacity-80 h-12 w-12 transition-opacity hover:opacity-100"
              >
                <div className="hidden md:block">
                  <Facebook className="xl:h-12 h-10"/>
                </div>
                <div className="md:hidden">
                  <FbMob />
                </div>
              </Typography>
            </div> */}
            </div>
          </div>
          {/* <hr className="mt-8 border-gray-600 opacity-20" /> */}
          <div className="flex w-full  border-gray-600 lg:flex-row md:hidden  flex-wrap md:items-center py-6 md:text-center md:justify-between">
            <div className="w-full py-2">
              <Typography className="text-white text-start text-sm font-plexmedium">
                Terms & Conditions
              </Typography>
              {/* <Typography
                variant="small"
                className="mb-4 text-start text-white md:mb-0 font-plexlight text-xs"
              >
                Copyright@immerso.ai {currentYear}
              </Typography> */}
            </div>
            <ul className="flex gap-y-2 md:items-center flex-col">
              <li>
                <Typography
                  as="a"
                  href="#"
                  color="blue-gray"
                  className="font-normal transition-colors text-white font-plexmedium text-sm"
                >
                  GDPR
                </Typography>
              </li>
              <li>
                <Typography
                  as="a"
                  href="#"
                  color="blue-gray"
                  className="font-normal transition-colors text-white font-plexmedium text-sm"
                >
                  Legal
                </Typography>
              </li>
              <li>
                <Typography
                  as="a"
                  href="/privacy-policy"
                  color="blue-gray"
                  className="font-normal transition-colors text-white font-plexmedium text-sm"
                >
                  Privacy Policy
                </Typography>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
